import { useEffect, useState } from "react";

export interface analysedFrequencyAudioDataInterface {
  peakFrequency: number;
  highFrequencyAverage: number;
  mediumHighFrequencyAverage: number;
  mediumFrequencyAverage: number;
  mediumLowFrequencyAverage: number;
  lowFrequencyAverage: number;
}

const useFrequencyAudioAnalyzer = (audioFrequencyData: number[]) => {
  const [analysedAudioData, setAnalysedAudioData] =
    useState<analysedFrequencyAudioDataInterface>({
      peakFrequency: 0,
      highFrequencyAverage: 0,
      mediumHighFrequencyAverage: 0,
      mediumFrequencyAverage: 0,
      mediumLowFrequencyAverage: 0,
      lowFrequencyAverage: 0,
    });

  useEffect(() => {
    let averageLow = 0;
    let averageMediumLow = 0;
    let averageMedium = 0;
    let averageMediumHigh = 0;
    let averageHigh = 0;
    const averageLowArray: number[] = [];
    const averageMediumLowArray: number[] = [];
    const averageMediumArray: number[] = [];
    const averageMediumHighArray: number[] = [];
    const averageHighArray: number[] = [];

    const oneFifthLength = Math.floor(audioFrequencyData.length / 5);

    for (let i = 0; i < oneFifthLength; i++) {
      averageLow += audioFrequencyData[i];
      averageLowArray.push(audioFrequencyData[i]);
    }

    for (let i = oneFifthLength; i < oneFifthLength * 2; i++) {
      averageMediumLow += audioFrequencyData[i];
      averageMediumLowArray.push(audioFrequencyData[i]);
    }

    for (let i = oneFifthLength * 2; i < oneFifthLength * 3; i++) {
      averageMedium += audioFrequencyData[i];
      averageMediumArray.push(audioFrequencyData[i]);
    }

    for (let i = oneFifthLength * 3; i < oneFifthLength * 4; i++) {
      averageMediumHigh += audioFrequencyData[i];
      averageMediumHighArray.push(audioFrequencyData[i]);
    }

    for (let i = oneFifthLength * 4; i < audioFrequencyData.length; i++) {
      averageHigh += audioFrequencyData[i];
      averageHighArray.push(audioFrequencyData[i]);
    }

    const averageLowCalculated = averageLow / oneFifthLength;
    const averageMediumLowCalculated = averageMediumLow / oneFifthLength;
    const averageMediumCalculated = averageMedium / oneFifthLength;
    const averageMediumHighCalculated = averageMediumHigh / oneFifthLength;
    const averageHighCalculated = averageHigh / oneFifthLength;

    const analysedAudioData: analysedFrequencyAudioDataInterface = {
      peakFrequency: Math.max.apply(null, audioFrequencyData) / 256,
      highFrequencyAverage: averageHighCalculated / 256,
      mediumHighFrequencyAverage: averageMediumHighCalculated / 256,
      mediumFrequencyAverage: averageMediumCalculated / 256,
      mediumLowFrequencyAverage: averageMediumLowCalculated / 256,
      lowFrequencyAverage: averageLowCalculated / 256,
    };

    setAnalysedAudioData(analysedAudioData);
  }, [audioFrequencyData]);

  return analysedAudioData;
};

export default useFrequencyAudioAnalyzer;
