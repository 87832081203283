import React from "react";
import styles from "./About.module.css";

const About = () => {
  return (
    <div className={styles.AboutContent}>
      <h2>About the 3D developer</h2>
      <div className={styles.RoundProfile}>
        <img src={"assets/20220202GD.jpg"} alt={"GD profile picture"} />
      </div>
      <p>Author: Gustav During</p>
      <p>
        <a href={"https://portfolio.wildur.se/contact.php"}>
          Contact at portfolio
        </a>
      </p>
    </div>
  );
};

export default About;
