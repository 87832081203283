import { atom } from "recoil";

export const fpsDisplayState = atom({
  key: "fpsDisplayState",
  default: true,
});

export const currentVisualization = atom({
  key: "currentVisualization",
  default: 3,
});

export const weights = atom({
  key: "weights",
  default: new Array(4),
});

export const weights2 = atom({
  key: "weights2",
  default: new Array(4),
});

export const storedCurrentAnimation = atom({
  key: "storedCurrentAnimation",
  default: 3,
});

export const storedCurrentAnimation2 = atom({
  key: "storedCurrentAnimation2",
  default: 3,
});
