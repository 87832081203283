import { useEffect, useState } from "react";

export interface analysedTimeDomainAudioDataInterface {
  averageTimeDomain: number;
}

const useTimeDomainAudioAnalyzer = (audioTimeDomainData: number[]) => {
  const [analysedAudioData, setAnalysedAudioData] =
    useState<analysedTimeDomainAudioDataInterface>({
      averageTimeDomain: 0,
    });

  useEffect(() => {
    let average = 0;
    for (let i = 0; i < audioTimeDomainData.length; i++) {
      average += audioTimeDomainData[i];
    }

    const averageCalculated = average / audioTimeDomainData.length;

    const analysedAudioData: analysedTimeDomainAudioDataInterface = {
      averageTimeDomain: averageCalculated / 128,
    };

    setAnalysedAudioData(analysedAudioData);
  }, [audioTimeDomainData]);

  return analysedAudioData;
};

export default useTimeDomainAudioAnalyzer;
