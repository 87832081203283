import React, { useEffect, useState } from "react";
import styles from "./App.module.css";
import Sketch from "./components/particles/ParticlesSketch";
import ThreeSketch from "./components/three/ThreeSketch";
import useAudioInput from "./hooks/useAudioInput";
import Overlay from "./components/controls/Overlay";
// import AudioVisualiserDebugDisplay from "./components/debug/AudioVisualiserDebugDisplay";

function App() {
  const [perf, setPerf] = useState(0.5);
  const [dataItemsArrayFrequency, setDataItemsArrayFrequency] = useState<
    number[]
  >([]);
  const [dataItemsArrayTimeDomain, setDataItemsArrayTimeDomain] = useState<
    number[]
  >([]);
  const [
    running,
    setRunning,
    analyser,
    audioFrequencyData,
    timeDomainAudioData,
  ]: any = useAudioInput();

  useEffect(() => {
    const dataItems: string = audioFrequencyData.toString();
    const splitItems = dataItems.split(",");
    const splitItemsNumber = splitItems.map((item) => Number(item));
    setDataItemsArrayFrequency(splitItemsNumber);
  }, [audioFrequencyData]);

  useEffect(() => {
    const dataItems: string = timeDomainAudioData.toString();
    const splitItems = dataItems.split(",");
    const splitItemsNumber = splitItems.map((item) => Number(item));
    setDataItemsArrayTimeDomain(splitItemsNumber);
  }, [timeDomainAudioData]);

  return (
    <div className={styles.App}>
      {/*<AudioVisualiserDebugDisplay*/}
      {/*  analyser={analyser}*/}
      {/*  dataItemsArrayTimeDomain={dataItemsArrayTimeDomain}*/}
      {/*  dataItemsArrayFrequency={dataItemsArrayFrequency}*/}
      {/*/>*/}
      <ThreeSketch
        perf={perf}
        frequencyAudioData={dataItemsArrayFrequency}
        timeDomainAudioData={dataItemsArrayTimeDomain}
      />
      <Sketch
        perf={perf}
        frequencyAudioData={dataItemsArrayFrequency}
        timeDomainAudioData={dataItemsArrayTimeDomain}
      />
      <Overlay
        running={running}
        setRunning={setRunning}
        perf={perf}
        setPerf={setPerf}
      />
    </div>
  );
}

export default App;
