import React, { useEffect, useState } from "react";
import {Canvas, extend, useFrame, useThree} from "@react-three/fiber";
import styles from "./ThreeSketch.module.css";
import useFrequencyAudioAnalyzer from "../../hooks/useFrequencyAudioAnalyzer";
import useTimeDomainAudioAnalyzer from "../../hooks/useTimeDomainAudioAnalyzer";
import { fpsDisplayState } from "../../store";
import {
  useRecoilBridgeAcrossReactRoots_UNSTABLE,
  useRecoilState,
} from "recoil";
import VisualizationChooser from "./VisualizationChooser";
import {
  ColorManagement,
  ACESFilmicToneMapping,
  BoxGeometry,
  Color,
  CylinderGeometry,
  LinearSRGBColorSpace,
  Mesh, MeshStandardMaterial,
  Vector3
} from "three";

extend({
  BoxGeometry,
  Color,
  CylinderGeometry,
  Mesh, MeshStandardMaterial,
  Vector3 })

ColorManagement.enabled = true;

interface ComponentProps {
  perf: number;
  frequencyAudioData: number[];
  timeDomainAudioData: number[];
}

interface performanceControlProps {
  perf: number;
}

interface fpsProps {
  setFps: React.Dispatch<React.SetStateAction<number>>;
}

function PerformanceListener(props: performanceControlProps) {
  const { gl } = useThree();
  useEffect(() => {
    gl.setPixelRatio(window.devicePixelRatio * props.perf);
    gl.setSize(innerWidth, innerHeight);
  }, [props.perf]);
  return <></>;
}

let countPerSecond = 0;
let timePassed = 0;
const oneSecond = 1;

function Fps(props: fpsProps) {
  useFrame((state, delta) => {
    timePassed += delta;
    countPerSecond += 1;
    if (timePassed > oneSecond) {
      const averageDeltaLastSecond = timePassed / countPerSecond;
      props.setFps(Math.floor(1 / averageDeltaLastSecond));
      timePassed = 0;
      countPerSecond = 0;
    }
  });
  return <></>;
}

function HandleOrientation (props: performanceControlProps) {
  const { gl } = useThree();

  useEffect(() => {
    const handleOrientation = () => {
      gl.setPixelRatio(window.devicePixelRatio * props.perf);
      gl.setSize(innerWidth, innerHeight);
    }
    window.addEventListener("deviceorientation", handleOrientation, true);
    return () => {
      window.removeEventListener("deviceorientation", handleOrientation);
    }
  }, [])

    return <></>;
}

export default (props: ComponentProps) => {
  const [fps, setFps] = useState(0);
  const [threeFpsDisplayActivated] =
    useRecoilState(fpsDisplayState);
  const analysedAudioData = useFrequencyAudioAnalyzer(props.frequencyAudioData);
  const analysedTimeDomainData = useTimeDomainAudioAnalyzer(
    props.timeDomainAudioData
  );
  const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE();

  return (
    <div className={styles.Overlay}>
      <div className={styles.FpsDisplay}>
        <div className={styles.Text}>
          {threeFpsDisplayActivated ? "FPS: " + fps : ""}
        </div>
      </div>
      <Canvas dpr={[window.devicePixelRatio * props.perf, window.devicePixelRatio * props.perf]} gl={{ antialias: false, toneMapping: ACESFilmicToneMapping, outputColorSpace: LinearSRGBColorSpace }}>
        <RecoilBridge>
          <HandleOrientation perf={props.perf} />
          <PerformanceListener perf={props.perf} />
          <Fps setFps={setFps} />
          <VisualizationChooser
            analysedAudioData={analysedAudioData}
            analysedTimeDomainData={analysedTimeDomainData}
          />
        </RecoilBridge>
      </Canvas>
    </div>
  );
};
