import { Vector3 } from "three";
import { useFrame } from "@react-three/fiber";

interface CameraProps {
  currentAnimation: number;
  timeDomainAverage: number;
}

const Camera3 = (props: CameraProps) => {
  useFrame((state) => {
    if (props.currentAnimation !== 3) {
      const timer = Date.now() * 0.001;
      state.camera.near = 0.1;
      state.camera.far = 1000;
      state.camera.position.x = Math.cos(timer) * 5;
      state.camera.position.y = 1.1;
      state.camera.position.z =
        Math.sin(props.timeDomainAverage) * 7 + Math.sin(timer);

      state.camera.lookAt(new Vector3(0, 0.8, 0));

      state.camera.zoom = 1.0;
      state.camera.updateProjectionMatrix();
    }
  });
  return <></>
};

export default Camera3;
