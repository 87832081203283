import { useEffect, useState } from "react";

const getMedia = async () => {
  try {
    return await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      console.error("Error:", err.message);
    }
    console.error(err);
  }
};

const useAudioInput = () => {
  const [analyser, setAnalyser] = useState<AnalyserNode | null>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [source, setSource] = useState<MediaStreamAudioSourceNode | null>(null);
  const [running, setRunning] = useState(false);
  const [audioFrequencyData, setAudioFrequencyData] = useState(
    new Uint8Array(0)
  );
  const [timeDomainAudioData, setTimeDomainAudioData] = useState(
    new Uint8Array(0)
  );

  const handleMicrophoneDeactivated = () => {
    if (stream) {
      if (analyser) {
        const dataFrequencyArray = new Uint8Array(analyser.frequencyBinCount);
        const dataTimeDomainArray = new Uint8Array(analyser.fftSize);
        setAudioFrequencyData(dataFrequencyArray);
        setTimeDomainAudioData(dataTimeDomainArray);
        analyser.disconnect();
      }
      stream.getTracks().forEach(function (track) {
        track.stop();
        stream.removeTrack(track);
      });
      if (source) {
        source.disconnect();
        setSource(null);
        setAnalyser(null);
        setStream(null);
      }
    }
  };

  useEffect(() => {
    if (running) {
      const audioContext = new AudioContext();

      getMedia().then((stream) => {
        if (audioContext.state === "closed") {
          return;
        }
        if (stream) {
          setSource(() => audioContext.createMediaStreamSource(stream));
          const newAnalyser = audioContext.createAnalyser();
          setAnalyser(newAnalyser);
          setStream(stream);
        }
      });

      return () => {
        if (analyser) {
          setAnalyser(null);
        }
        if (source) {
          source.disconnect();
          setSource(null);
        }
        if (audioContext) {
          audioContext.close();
        }
      };
    } else {
      handleMicrophoneDeactivated();
    }

    return () => {
      if (analyser) {
        setAnalyser(null);
      }
      if (source) {
        setSource(null);
      }
    };
  }, [running]);

  useEffect(() => {
    if (!analyser || !source || !running) {
      return;
    }

    source.connect(analyser);
    setAnalyser(analyser);
  }, [analyser, source]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (analyser) {
        const dataFrequencyArray = new Uint8Array(analyser.frequencyBinCount);
        const dataTimeDomainArray = new Uint8Array(analyser.fftSize);
        analyser.getByteFrequencyData(dataFrequencyArray);
        setAudioFrequencyData(dataFrequencyArray);
        analyser.getByteTimeDomainData(dataTimeDomainArray);
        setTimeDomainAudioData(dataTimeDomainArray);
      }
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [analyser]);

  return [
    running,
    setRunning,
    analyser,
    audioFrequencyData,
    timeDomainAudioData,
  ];
};

export default useAudioInput;
