import React from "react";
import useFrequencyAudioAnalyzer from "../../hooks/useFrequencyAudioAnalyzer";
import useTimeDomainAudioAnalyzer from "../../hooks/useTimeDomainAudioAnalyzer";
import VisualizationChooser from "./VisualizationChooser";
import styles from "./ParticleSketch.module.css"

interface ComponentProps {
  perf: number;
  frequencyAudioData: number[];
  timeDomainAudioData: number[];
}

export default (props: ComponentProps) => {
  const analysedAudioData = useFrequencyAudioAnalyzer(props.frequencyAudioData);
  const analysedTimeDomainData = useTimeDomainAudioAnalyzer(
    props.timeDomainAudioData
  );

  return (
    <div className={styles.ParticleContainer}>
      <VisualizationChooser
        analysedAudioData={analysedAudioData}
        analysedTimeDomainData={analysedTimeDomainData}
        perf={props.perf}
      />
    </div>
  );
};
