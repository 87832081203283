import { analysedFrequencyAudioDataInterface } from "../../hooks/useFrequencyAudioAnalyzer";
import { analysedTimeDomainAudioDataInterface } from "../../hooks/useTimeDomainAudioAnalyzer";
import Visualization1 from "./visualization/Visualization1";
import Visualization2 from "./visualization/Visualization2";
import Visualization3 from "./visualization/Visualization3";
import {useRecoilState} from "recoil";
import {currentVisualization} from "../../store";
import Visualization4 from "./visualization/Visualization4";

export interface VisualizationProps {
  analysedAudioData: analysedFrequencyAudioDataInterface;
  analysedTimeDomainData: analysedTimeDomainAudioDataInterface;
}

interface VisualizationChooserProps {
  analysedAudioData: analysedFrequencyAudioDataInterface;
  analysedTimeDomainData: analysedTimeDomainAudioDataInterface;
}

const VisualizationChooser = (props: VisualizationChooserProps) => {
  const [chosenVisualization] =
      useRecoilState(currentVisualization);

  let CurrentVisualization = <></>;

  switch (chosenVisualization) {
    case 1:
      CurrentVisualization = (
        <Visualization1
          analysedAudioData={props.analysedAudioData}
          analysedTimeDomainData={props.analysedTimeDomainData}
        />
      );
      break;
    case 2:
      CurrentVisualization = (
        <Visualization2
          analysedAudioData={props.analysedAudioData}
          analysedTimeDomainData={props.analysedTimeDomainData}
        />
      );
      break;
    case 3:
      CurrentVisualization = (
        <Visualization3
          analysedAudioData={props.analysedAudioData}
          analysedTimeDomainData={props.analysedTimeDomainData}
        />
      );
      break;
    case 4:
      CurrentVisualization = (
          <Visualization4
              analysedAudioData={props.analysedAudioData}
              analysedTimeDomainData={props.analysedTimeDomainData}
          />
      );
      break;
  }

  return <>{CurrentVisualization}</>;
};

export default VisualizationChooser;
