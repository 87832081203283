import React, { useEffect, useRef, useState } from "react";
import { extend, useFrame } from "@react-three/fiber";
import { VisualizationProps } from "../VisualizationChooser";
import {
  Mesh,
  Vector3,
  BoxGeometry,
  MeshStandardMaterial,
  ColorManagement,
  Color,
} from "three";

extend({ Mesh, Vector3, BoxGeometry, MeshStandardMaterial });

ColorManagement.enabled = true;

interface boxProps {
  meshProps: JSX.IntrinsicElements["mesh"];
  dynamicScale: number;
  dynamicMovement: number;
}

function Box(props: boxProps) {
  const ref = useRef<Mesh>(null);
  const [hovered, hover] = useState(false);
  const [clicked, click] = useState(false);
  const [scale, setScale] = useState(0);
  useFrame(() => {
    if (ref === null || ref.current === null) {
      return;
    }
    return (ref.current.rotation.x += clicked
      ? 0.01 * props.dynamicMovement
      : 0.01);
  });

  useEffect(() => {
    setScale(props.dynamicScale);
  }, [props.dynamicScale]);

  return (
    <mesh
      {...props.meshProps}
      ref={ref}
      scale={clicked ? 1.1 * scale + 1.2 : 1}
      onClick={() => click(!clicked)}
      onPointerOver={() => hover(true)}
      onPointerOut={() => hover(false)}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={hovered ? "hotpink" : "orange"} />
    </mesh>
  );
}

const Visualization1 = (props: VisualizationProps) => {
  const positionOne = { position: new Vector3(-6.8, 0, 0) };
  const positionTwo = { position: new Vector3(-3.4, 0, 0) };
  const positionThree = { position: new Vector3(-1.2, 0, 0) };
  const positionFour = { position: new Vector3(1.2, 0, 0) };
  const positionFive = { position: new Vector3(3.4, 0, 0) };
  const positionSix = { position: new Vector3(6.8, 0, 0) };

  return (
    <>
      <ambientLight />
      <pointLight
        position={[10, 10, 10]}
        intensity={7.0}
        decay={1.5}
        color={new Color(100, 50, 50)}
        castShadow={false}
      />
      <Box
        meshProps={positionOne}
        dynamicScale={props.analysedAudioData.peakFrequency}
        dynamicMovement={props.analysedTimeDomainData.averageTimeDomain}
      />
      <Box
        meshProps={positionTwo}
        dynamicScale={props.analysedAudioData.lowFrequencyAverage * 10}
        dynamicMovement={props.analysedTimeDomainData.averageTimeDomain}
      />
      <Box
        meshProps={positionThree}
        dynamicScale={props.analysedAudioData.mediumLowFrequencyAverage * 10}
        dynamicMovement={props.analysedTimeDomainData.averageTimeDomain}
      />
      <Box
        meshProps={positionFour}
        dynamicScale={props.analysedAudioData.mediumFrequencyAverage * 10}
        dynamicMovement={props.analysedTimeDomainData.averageTimeDomain}
      />
      <Box
        meshProps={positionFive}
        dynamicScale={props.analysedAudioData.mediumHighFrequencyAverage * 10}
        dynamicMovement={props.analysedTimeDomainData.averageTimeDomain}
      />
      <Box
        meshProps={positionSix}
        dynamicScale={props.analysedAudioData.highFrequencyAverage * 10}
        dynamicMovement={props.analysedTimeDomainData.averageTimeDomain}
      />
    </>
  );
};

export default Visualization1;
