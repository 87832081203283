import React, { useState } from "react";
import styles from "./Overlay.module.css";
import { useRecoilState } from "recoil";
import {
  currentVisualization,
  fpsDisplayState,
  storedCurrentAnimation,
  weights,
} from "../../store";
import About from "../about/About";

interface OverLayProps {
  running: boolean;
  setRunning: React.Dispatch<React.SetStateAction<boolean>>;
  perf: number;
  setPerf: React.Dispatch<React.SetStateAction<number>>;
}

const Overlay = React.memo((props: OverLayProps) => {
  const [overlayPanelIsVisible, setOverlayPanelIsVisible] = useState(true);
  const [panelPageIsVisible, setPanelPageIsVisible] = useState("settings");
  const [threeFpsDisplayActivated, setThreeFpsDisplayActivated] =
    useRecoilState(fpsDisplayState);
  const [chosenVisualization, setChosenVisualization] =
    useRecoilState(currentVisualization);
  const [allWeights] = useRecoilState(weights);
  const [currentAnimation] = useRecoilState(storedCurrentAnimation);
  const numberOfVisualization = 4;

  function handlePerformanceChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newPerformanceValue: number = +e.target.value;
    props.setPerf(() => newPerformanceValue);
  }

  function previousVisualization() {
    if (chosenVisualization > 1) {
      setChosenVisualization((prev) => (prev -= 1));
    }
  }

  function nextVisualization() {
    if (chosenVisualization < numberOfVisualization) {
      setChosenVisualization((prev) => (prev += 1));
    }
  }

  function togglePanels(panel: string) {
    if (panel === "settings") {
      if (panel !== panelPageIsVisible) {
        setPanelPageIsVisible("settings");
        setOverlayPanelIsVisible(true);
      } else {
        setOverlayPanelIsVisible(!overlayPanelIsVisible);
      }
    } else if (panel === "about") {
      if (panel !== panelPageIsVisible) {
        setPanelPageIsVisible("about");
        setOverlayPanelIsVisible(true);
      } else {
        setOverlayPanelIsVisible(!overlayPanelIsVisible);
      }
    }
  }

  const displayZeroWeight =
    allWeights[0] !== undefined && allWeights[0] ? allWeights[0].toFixed(2) : 0;
  const displayOneWeight =
    allWeights[1] !== undefined && allWeights[1] ? allWeights[1].toFixed(2) : 0;
  const displayTwoWeight =
    allWeights[2] !== undefined && allWeights[2] ? allWeights[2].toFixed(2) : 0;
  const displayIdleWeight =
    allWeights[3] !== undefined && allWeights[3] ? allWeights[3].toFixed(2) : 0;

  return (
    <div className={styles.OverlayControls}>
      <div className={styles.OverlayControlsOnly}>
        <button
          className={styles.ButtonIcon}
          type="button"
          onClick={() => togglePanels("settings")}
        >
          <img
            src={"assets/icons/settings-line.svg"}
            alt={"settings"}
            title={"Hide or show settings overlay"}
          />
        </button>
        <button
          className={styles.ButtonIcon}
          type="button"
          onClick={() => togglePanels("about")}
        >
          <img
            src={"assets/icons/about-me-icon.svg"}
            alt={"about"}
            title={"Hide or show about the developer"}
          />
        </button>
      </div>
      {overlayPanelIsVisible && panelPageIsVisible === "settings" ? (
        <div className={styles.OverlayBackground}>
          <button
            className={styles.ButtonIconWithText}
            onClick={() => props.setRunning(!props.running)}
          >
            {!props.running ? (
              <>
                <img src={"assets/icons/microphone-line.svg"} alt={"mic on"} />
                <span>Press to activate microphone</span>
              </>
            ) : (
              <>
                <img src={"assets/icons/mic-off.svg"} alt={"mic off"} />
                <span>Press to deactivate microphone</span>
              </>
            )}
          </button>
          <button
            className={styles.ButtonIconWithText}
            type="button"
            onClick={() =>
              setThreeFpsDisplayActivated(!threeFpsDisplayActivated)
            }
          >
            {threeFpsDisplayActivated ? (
              <>
                <img
                  src={"assets/icons/hide-private-hidden.svg"}
                  alt={"SHOW FPS"}
                />
                <span>Hide FPS</span>
              </>
            ) : (
              <>
                <img src={"assets/icons/eye-look.svg"} alt={"Hide FPS"} />
                <span>Show FPS</span>
              </>
            )}
          </button>
          <div className={styles.FlexRowWithWhiteText}>
            <p>{"<--Performance"}</p>
            <p>{"Quality -->"}</p>
          </div>
          <input
            type="range"
            min="0.05"
            max="1"
            step="0.01"
            value={props.perf}
            onChange={handlePerformanceChange}
          />
          <div className={styles.FlexRowWithWhiteText}>
            <button
              className={
                chosenVisualization < 2
                  ? styles.ButtonIconInactive
                  : styles.ButtonIcon
              }
              onClick={previousVisualization}
            >
              <img
                src={"assets/icons/angle-circle-left.svg"}
                alt={"Previous"}
              />
            </button>
            <div>Visualization {chosenVisualization}</div>
            <button
              className={
                chosenVisualization === numberOfVisualization
                  ? styles.ButtonIconInactive
                  : styles.ButtonIcon
              }
              onClick={nextVisualization}
            >
              <img src={"assets/icons/angle-circle-right.svg"} alt={"Next"} />
            </button>
          </div>
          <div className={styles.FlexRowWithWhiteText}>
            <p>
              Press activate microphone and allow it in your browser popup to
              make Visualization react to sound from microphone or "stereo mix".
              <br />
              <br />
              In some visualization you may need to click the 3D elements on
              screen as well.
            </p>
          </div>
          {chosenVisualization === 3 ? (
            <>
              <div className={styles.WhiteText}>
                Current animation: {currentAnimation}
              </div>
              <div className={styles.WhiteText}>
                Weight animation zero: {displayZeroWeight}
              </div>
              <div className={styles.WhiteText}>
                Weight animation one: {displayOneWeight}
              </div>
              <div className={styles.WhiteText}>
                Weight animation two: {displayTwoWeight}
              </div>
              <div className={styles.WhiteText}>
                Weight idle: {displayIdleWeight}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <>
          {overlayPanelIsVisible && panelPageIsVisible === "about" ? (
            <div className={styles.OverlayBackground}>
              <About />
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
});

export default Overlay;
