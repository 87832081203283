import React, { useState } from "react";
import Sketch from "react-p5";
import p5Types from "p5";
import { VisualizationProps } from "../VisualizationChooser";

let canvasImage: p5Types.Graphics;

let scale = 1;
const maxScale = 500;

const Visualization4 = (props: VisualizationProps) => {
  const [oldPerf, setOldPerf] = useState(props.perf);

  const perf = props.perf;

  const setup = (p5: p5Types, canvasParentRef: Element) => {
    // use parent to render the canvas in this ref
    // (without that p5 will render the canvas outside your component)
    canvasImage = p5.createGraphics(
      p5.windowWidth * perf,
      p5.windowHeight * perf
    );
    p5.createCanvas(p5.windowWidth, p5.windowHeight).parent(canvasParentRef);
  };

  const windowResized = (p5: p5Types) => {
    p5.resizeCanvas(p5.windowWidth, p5.windowHeight, false)
    canvasImage = p5.createGraphics(
        p5.windowWidth * perf,
        p5.windowHeight * perf
    );
    canvasImage.background(0);
  }

  const draw = (p5: p5Types) => {
    if (oldPerf !== perf) {
      canvasImage = p5.createGraphics(
        p5.windowWidth * perf,
        p5.windowHeight * perf
      );
      setOldPerf(() => perf);
    }
    canvasImage.background(0);

    const c = canvasImage.color(
      200 * props.analysedAudioData.peakFrequency,
      200 * props.analysedAudioData.peakFrequency,
      0,
      200 * props.analysedAudioData.peakFrequency
    );
    canvasImage.fill(c);

    canvasImage.ellipse(
      (p5.windowWidth * perf) / 2,
      (p5.windowHeight * perf) / 2,
      (500 + scale) * perf,
      (500 + scale) * perf
    );

    if (
      scale < maxScale &&
      scale <
        props.analysedTimeDomainData.averageTimeDomain * perf * p5.deltaTime
    ) {
      scale += 0.1 * perf * p5.deltaTime;
    } else if (
      scale >
      props.analysedTimeDomainData.averageTimeDomain * perf * p5.deltaTime
    ) {
      scale -= 0.1 * perf * p5.deltaTime;
    }

    p5.image(canvasImage, 0, 0, p5.windowWidth, p5.windowHeight);
  };

  return (
    <>
      <Sketch setup={setup} draw={draw} windowResized={windowResized} />
    </>
  );
};

export default Visualization4;
