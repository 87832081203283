import React from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./index.css";
import App from "./App";
import { RecoilRoot } from "recoil";
import process from "process";

import { createRoot } from 'react-dom/client';

const development: boolean =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export default function isDev(): boolean {
  return development;
}

Sentry.init({
  dsn: "https://50d977fe035b450e83ad03b54d1dcfa6@o363870.ingest.sentry.io/6246112",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  environment: isDev() ? "development" : "production",
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<React.StrictMode>
    <RecoilRoot>
        <App />
    </RecoilRoot>
</React.StrictMode>);
