import React, { Suspense, useEffect, useRef, useState } from "react";
import { useFrame, extend } from "@react-three/fiber";
import { VisualizationProps } from "../VisualizationChooser";
import Dancer from "./Visualization3/Dancer";
import { useRecoilState } from "recoil";
import {
  storedCurrentAnimation,
  storedCurrentAnimation2,
} from "../../../store";
import Scene from "./Visualization3/Scene";
import Loader from "./Visualization3/Loader";
import Camera3 from "./Visualization3/Camera3";
import Dancer2 from "./Visualization4/Dancer2";
import {
  Color,
  Vector3,
  Mesh,
  BoxGeometry,
  CylinderGeometry,
  MeshStandardMaterial,
  ColorManagement,
} from "three";

extend({
  Color,
  Vector3,
  Mesh,
  BoxGeometry,
  CylinderGeometry,
  MeshStandardMaterial,
});

ColorManagement.enabled = true;

interface boxProps {
  meshProps: JSX.IntrinsicElements["mesh"];
  dynamicScale: number;
  dynamicMovement: number;
}

function Cylinder(props: boxProps) {
  const ref = useRef<Mesh>(null);
  const [hovered, hover] = useState(false);
  const [clicked, click] = useState(false);
  const [scale, setScale] = useState(0);
  useFrame(() => {
    if (ref === null || ref.current === null) {
      return;
    }
    return (ref.current.rotation.y += clicked
      ? 0.01 * props.dynamicMovement
      : 0.01);
  });

  useEffect(() => {
    setScale(props.dynamicScale);
  }, [props.dynamicScale]);

  return (
    <mesh
      {...props.meshProps}
      ref={ref}
      scale={[0.1, clicked ? 1.2 * scale * 0.1 : scale * 0.1, 0.1]}
      onClick={() => click(!clicked)}
      onPointerOver={() => hover(true)}
      onPointerOut={() => hover(false)}
    >
      <cylinderGeometry args={[5, 5, 20, 32]} />
      <meshStandardMaterial color={hovered ? "purple" : "darkred"} />
    </mesh>
  );
}

const initialCurrentAnimation = 3;
let switchToAnimation = initialCurrentAnimation;

const Visualization4 = (props: VisualizationProps) => {
  const [currentAnimation, setCurrentAnimation] = useRecoilState(
    storedCurrentAnimation,
  );

  const [currentAnimation2, setCurrentAnimation2] = useRecoilState(
    storedCurrentAnimation2,
  );

  useEffect(() => {
    switchToAnimation = 2;

    if (props.analysedAudioData.peakFrequency < 0.1) {
      switchToAnimation = initialCurrentAnimation;
    }

    if (
      props.analysedAudioData.mediumFrequencyAverage >
      props.analysedAudioData.mediumLowFrequencyAverage
    ) {
      switchToAnimation = 1;
    }

    if (
      props.analysedAudioData.highFrequencyAverage >
        props.analysedAudioData.mediumLowFrequencyAverage * 0.2 ||
      props.analysedAudioData.highFrequencyAverage * 3 >
        props.analysedAudioData.mediumHighFrequencyAverage * 3
    ) {
      switchToAnimation = 0;
    }

    if (currentAnimation !== switchToAnimation) {
      setCurrentAnimation(switchToAnimation);
    }

    if (currentAnimation2 !== switchToAnimation) {
      setCurrentAnimation2(switchToAnimation);
    }
  }, [props.analysedTimeDomainData]);

  const positionOne = {
    position: new Vector3(-6.8, -1.7, -2),
  };
  const positionTwo = {
    position: new Vector3(-3.4, -1.7, -2),
  };
  const positionThree = {
    position: new Vector3(0, -1.7, -2),
  };
  const positionFour = {
    position: new Vector3(3.4, -1.7, -2),
  };
  const positionFive = {
    position: new Vector3(6.8, -1.7, -2),
  };

  return (
    <>
      <pointLight
        position={[
          10 * props.analysedAudioData.peakFrequency + 10,
          10 * props.analysedAudioData.peakFrequency + 10,
          10 * props.analysedAudioData.peakFrequency + 0.5,
        ]}
        intensity={7.0}
        decay={1.5}
        color={new Color(100, 50, 50)}
        castShadow={false}
      />
      <pointLight
        position={[
          -10 * props.analysedAudioData.peakFrequency - 10,
          -10 * props.analysedAudioData.peakFrequency - 10,
          10 * props.analysedAudioData.peakFrequency - 0.5,
        ]}
        intensity={7.0}
        decay={1.5}
        color={new Color(50, 100, 150)}
        castShadow={false}
      />
      <Scene />
      <Camera3
        currentAnimation={currentAnimation}
        timeDomainAverage={props.analysedTimeDomainData.averageTimeDomain}
      />
      <Suspense fallback={<Loader x={-2.0} y={-2.0} z={0.0} />}>
        <Dancer
          currentAnimation={currentAnimation}
          timeDomainAverage={props.analysedTimeDomainData.averageTimeDomain}
          peakFrequency={props.analysedAudioData.peakFrequency}
          position={[-2.0, -2.0, 0.0]}
        />
      </Suspense>
      <Suspense fallback={<Loader x={0.0} y={-2.0} z={0.0} />}>
        <Dancer
          currentAnimation={currentAnimation}
          timeDomainAverage={props.analysedTimeDomainData.averageTimeDomain}
          peakFrequency={props.analysedAudioData.peakFrequency}
          position={[0.0, -2.0, 0.0]}
        />
      </Suspense>
      <Suspense fallback={<Loader x={2.0} y={-2.0} z={0.0} />}>
        <Dancer2
          currentAnimation={currentAnimation2}
          timeDomainAverage={props.analysedTimeDomainData.averageTimeDomain}
          peakFrequency={props.analysedAudioData.peakFrequency}
          position={[2.0, -2.0, 0.0]}
        />
      </Suspense>
      <Cylinder
        meshProps={positionOne}
        dynamicScale={props.analysedAudioData.lowFrequencyAverage * 5}
        dynamicMovement={props.analysedTimeDomainData.averageTimeDomain}
      />
      <Cylinder
        meshProps={positionTwo}
        dynamicScale={props.analysedAudioData.mediumLowFrequencyAverage * 5}
        dynamicMovement={props.analysedTimeDomainData.averageTimeDomain}
      />
      <Cylinder
        meshProps={positionThree}
        dynamicScale={props.analysedAudioData.mediumFrequencyAverage * 5}
        dynamicMovement={props.analysedTimeDomainData.averageTimeDomain}
      />
      <Cylinder
        meshProps={positionFour}
        dynamicScale={props.analysedAudioData.mediumHighFrequencyAverage * 5}
        dynamicMovement={props.analysedTimeDomainData.averageTimeDomain}
      />
      <Cylinder
        meshProps={positionFive}
        dynamicScale={props.analysedAudioData.highFrequencyAverage * 5}
        dynamicMovement={props.analysedTimeDomainData.averageTimeDomain}
      />
    </>
  );
};

export default Visualization4;
