import React, { useEffect, useRef, useState } from "react";
import { Color, ColorManagement, Mesh, Vector3 } from "three";
import { useFrame } from "@react-three/fiber";
import { VisualizationProps } from "../VisualizationChooser";

ColorManagement.enabled = true;

interface boxProps {
  meshProps: JSX.IntrinsicElements["mesh"];
  dynamicScale: number;
  dynamicMovement: number;
}

function CameraControl() {
  useFrame((state) => {
    state.camera.near = 0.1;
    state.camera.far = 1000;
    state.camera.position.x = 0;
    state.camera.position.y = 0;
    state.camera.position.z = 5;

    state.camera.lookAt(new Vector3(0, 0, 0));

    state.camera.zoom = 1.0;
    state.camera.updateProjectionMatrix();
  });
  return <></>;
}

function Box(props: boxProps) {
  const ref = useRef<Mesh>(null);
  const [hovered, hover] = useState(false);
  const [clicked, click] = useState(false);
  const [scale, setScale] = useState(0);
  useFrame(() => {
    if (ref === null || ref.current === null) {
      return;
    }
    return (ref.current.rotation.x += clicked
      ? 0.01 * props.dynamicMovement
      : 0.01);
  });

  useEffect(() => {
    setScale(props.dynamicScale);
  }, [props.dynamicScale]);

  return (
    <mesh
      {...props.meshProps}
      ref={ref}
      scale={clicked ? 1.1 * scale + 1.2 : 1}
      onClick={() => click(!clicked)}
      onPointerOver={() => hover(true)}
      onPointerOut={() => hover(false)}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={hovered ? "purple" : "red"} />
    </mesh>
  );
}

const Visualization2 = (props: VisualizationProps) => {
  const positionOne = {
    position: new Vector3(-6.8, 3.0 * props.analysedAudioData.peakFrequency, 0),
  };
  const positionTwo = {
    position: new Vector3(
      -3.4,
      3.0 * props.analysedAudioData.lowFrequencyAverage,
      0,
    ),
  };
  const positionThree = {
    position: new Vector3(
      -1.2,
      3.0 * props.analysedAudioData.mediumLowFrequencyAverage,
      0,
    ),
  };
  const positionFour = {
    position: new Vector3(
      1.2,
      3.0 * props.analysedAudioData.mediumFrequencyAverage,
      0,
    ),
  };
  const positionFive = {
    position: new Vector3(
      3.4,
      3.0 * props.analysedAudioData.mediumHighFrequencyAverage,
      0,
    ),
  };
  const positionSix = {
    position: new Vector3(
      6.8,
      3.0 * props.analysedAudioData.highFrequencyAverage,
      0,
    ),
  };

  return (
    <>
      <ambientLight />
      <pointLight
        position={[
          10 * props.analysedAudioData.peakFrequency,
          10 * props.analysedAudioData.peakFrequency,
          10 * props.analysedAudioData.peakFrequency,
        ]}
        intensity={7.0}
        decay={1.5}
        color={new Color(100, 50, 50)}
        castShadow={false}
      />
      <CameraControl />
      <Box
        meshProps={positionOne}
        dynamicScale={props.analysedAudioData.peakFrequency}
        dynamicMovement={props.analysedTimeDomainData.averageTimeDomain}
      />
      <Box
        meshProps={positionTwo}
        dynamicScale={props.analysedAudioData.lowFrequencyAverage * 5}
        dynamicMovement={props.analysedTimeDomainData.averageTimeDomain}
      />
      <Box
        meshProps={positionThree}
        dynamicScale={props.analysedAudioData.mediumLowFrequencyAverage * 5}
        dynamicMovement={props.analysedTimeDomainData.averageTimeDomain}
      />
      <Box
        meshProps={positionFour}
        dynamicScale={props.analysedAudioData.mediumFrequencyAverage * 5}
        dynamicMovement={props.analysedTimeDomainData.averageTimeDomain}
      />
      <Box
        meshProps={positionFive}
        dynamicScale={props.analysedAudioData.mediumHighFrequencyAverage * 5}
        dynamicMovement={props.analysedTimeDomainData.averageTimeDomain}
      />
      <Box
        meshProps={positionSix}
        dynamicScale={props.analysedAudioData.highFrequencyAverage * 5}
        dynamicMovement={props.analysedTimeDomainData.averageTimeDomain}
      />
    </>
  );
};

export default Visualization2;
