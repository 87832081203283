import React, { useState } from "react";
import Sketch from "react-p5";
import p5Types from "p5";
import { VisualizationProps } from "../VisualizationChooser";

let speedX = 1;
let canvasImage: p5Types.Graphics;

const Visualization1 = (props: VisualizationProps) => {
  const [oldPerf, setOldPerf] = useState(props.perf);
  const [x, setX] = useState(50);
  const [y, setY] = useState(150);
  const [directionRight, setDirectionRight] = useState(true);
  // const analysedAudioData = useFrequencyAudioAnalyzer(props.frequencyAudioData);
  // const analysedTimeDomainData = useTimeDomainAudioAnalyzer(
  //     props.timeDomainAudioData
  // );
  const xInitial = 50;
  const yInitial = 150;

  const perf = props.perf;

  const setup = (p5: p5Types, canvasParentRef: Element) => {
    // use parent to render the canvas in this ref
    // (without that p5 will render the canvas outside your component)
    canvasImage = p5.createGraphics(
      p5.windowWidth * perf,
      p5.windowHeight * perf
    );
    setX(() => xInitial * perf);
    setY(() => yInitial * perf);
    p5.createCanvas(p5.windowWidth, p5.windowHeight).parent(canvasParentRef);
  };

  const windowResized = (p5: p5Types) => {
    p5.resizeCanvas(p5.windowWidth, p5.windowHeight, false)
    canvasImage = p5.createGraphics(
        p5.windowWidth * perf,
        p5.windowHeight * perf
    );
    canvasImage.background(0);
  }

  const draw = (p5: p5Types) => {
    if (oldPerf !== perf) {
      canvasImage = p5.createGraphics(
        p5.windowWidth * perf,
        p5.windowHeight * perf
      );
      setOldPerf(() => perf);
    }
    canvasImage.background(0);

    let c = canvasImage.color("#FFFFFF");
    canvasImage.fill(c);
    canvasImage.ellipse(x * perf, y * perf, 70 * perf, 70 * perf);

    c = canvasImage.color("#FF0000");
    canvasImage.fill(c);
    canvasImage.ellipse(
      x * perf,
      y * perf,
      200 * props.analysedAudioData.peakFrequency * perf,
      200 * props.analysedAudioData.peakFrequency * perf
    );
    // NOTE: Do not use setState in the draw function or in functions that are executed
    // in the draw function...
    // please use normal variables or class properties for these purposes

    setX(
      (prevState) =>
        prevState + speedX * props.analysedTimeDomainData.averageTimeDomain
    );

    if ((directionRight && x > p5.windowWidth) || (!directionRight && x < 0)) {
      speedX = speedX * -1;
      setDirectionRight(() => !directionRight);
    }
    p5.image(canvasImage, 0, 0, p5.windowWidth, p5.windowHeight);
  };

  return (
    <>
      <Sketch setup={setup} draw={draw} windowResized={windowResized} />
    </>
  );
};

export default Visualization1;
