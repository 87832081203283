import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";

import { extend } from '@react-three/fiber'
import {Mesh, BoxGeometry, MeshStandardMaterial, ColorManagement} from 'three'

extend({ Mesh, BoxGeometry, MeshStandardMaterial })

ColorManagement.enabled = true;

interface LoaderProps {
  x: number;
  y: number;
  z: number;
}

function Loader({x, y, z} : LoaderProps) {
  const ref = useRef<Mesh>(null);
  useFrame((state, delta) => {
    if (ref === null || ref.current === null) {
      return;
    }
    ref.current.position.x = x;
    ref.current.position.y = y + 2;
    ref.current.position.z = z;

    ref.current.rotation.y += delta + 3;
  });

  return (
    <mesh ref={ref}>
      <boxGeometry attach="geometry" args={[1, 1, 1]} />
      <meshStandardMaterial attach="material" transparent opacity={0.5} />
    </mesh>
  );
}

export default Loader;
