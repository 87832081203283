import React, { useEffect, useMemo, useRef } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Euler } from "three";
import { AnimationAction } from "three/src/animation/AnimationAction";
import { AnimationMixer } from "three/src/animation/AnimationMixer";
import { useRecoilState } from "recoil";
import { weights } from "../../../../store";
// @ts-ignore
import * as SkeletonUtils from "three/addons/utils/SkeletonUtils.js";

interface DancerProps {
  currentAnimation: number;
  timeDomainAverage: number;
  peakFrequency: number;
  position: [number, number, number];
}

let actions: AnimationAction[];
let actionZero: AnimationAction,
  actionOne: AnimationAction,
  actionTwo: AnimationAction,
  actionIdle: AnimationAction;
let actionZeroWeight = 0,
  actionOneWeight = 0,
  actionTwoWeight = 0,
  actionIdleWeight = 1;

function setWeight(action: AnimationAction, weight: number) {
  if (action) {
    action.enabled = true;
    action.setEffectiveTimeScale(1);
    action.setEffectiveWeight(weight);
  }
}

function activateAllActions() {
  setWeight(actionZero, actionZeroWeight);
  setWeight(actionOne, actionOneWeight);
  setWeight(actionTwo, actionTwoWeight);
  setWeight(actionIdle, actionIdleWeight);

  actions.forEach(function (action) {
    action.play();
  });
}

function crossFadeToIdle(delta: number) {
  if (actionZeroWeight > 0) {
    actionZeroWeight -= delta;
  } else {
    actionZeroWeight = 0;
  }

  if (actionOneWeight > 0) {
    actionOneWeight -= delta;
  } else {
    actionOneWeight = 0;
  }

  if (actionTwoWeight > 0) {
    actionTwoWeight -= delta;
  } else {
    actionTwoWeight = 0;
  }

  if (actionIdleWeight < 1) {
    actionIdleWeight += delta;
  } else {
    actionIdleWeight = 1;
  }
}

function crossFadeToAnimationZero(delta: number) {
  if (actionZeroWeight < 1) {
    actionZeroWeight += delta;
  } else {
    actionZeroWeight = 1;
  }

  if (actionOneWeight > 0) {
    actionOneWeight -= delta;
  } else {
    actionOneWeight = 0;
  }

  if (actionTwoWeight > 0) {
    actionTwoWeight -= delta;
  } else {
    actionTwoWeight = 0;
  }

  if (actionIdleWeight > 0) {
    actionIdleWeight -= delta;
  } else {
    actionIdleWeight = 0;
  }
}

function crossFadeToAnimationOne(delta: number, fadeSpeed = 10) {
  delta = delta * fadeSpeed;
  if (actionZeroWeight > 0) {
    actionZeroWeight -= delta;
  } else {
    actionZeroWeight = 0;
  }

  if (actionOneWeight < 1) {
    actionOneWeight += delta;
  } else {
    actionOneWeight = 1;
  }

  if (actionTwoWeight > 0) {
    actionTwoWeight -= delta;
  } else {
    actionTwoWeight = 0;
  }

  if (actionIdleWeight > 0) {
    actionIdleWeight -= delta;
  } else {
    actionIdleWeight = 0;
  }
}

function crossFadeToAnimationTwo(delta: number) {
  if (actionZeroWeight > 0) {
    actionZeroWeight -= delta;
  } else {
    actionZeroWeight = 0;
  }

  if (actionOneWeight > 0) {
    actionOneWeight -= delta;
  } else {
    actionOneWeight = 0;
  }

  if (actionTwoWeight < 1) {
    actionTwoWeight += delta;
  } else {
    actionTwoWeight = 1;
  }

  if (actionIdleWeight > 0) {
    actionIdleWeight -= delta;
  } else {
    actionIdleWeight = 0;
  }
}

const useMeshCloneForGLTF = () => {
  // @ts-ignore
  const { scene, materials, animations, nodes } = useLoader(
    GLTFLoader,
    "/assets/modded_dancer_V5.glb",
  );
  const copiedScene = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  return { scene: copiedScene, materials, animations, nodes };
};

const Dancer = (props: DancerProps) => {
  const animationMixer = useRef<AnimationMixer>();
  // const gltf = useLoader(GLTFLoader, "/assets/modded_dancer_V5.glb");
  const { scene, materials, animations, nodes } = useMeshCloneForGLTF();
  const [, setAllWeight] = useRecoilState(weights);

  useEffect(() => {
    if (scene && animations) {
      animationMixer.current = new AnimationMixer(scene);

      actionZero = animationMixer.current.clipAction(animations[0]);
      actionOne = animationMixer.current.clipAction(animations[1]);
      actionTwo = animationMixer.current.clipAction(animations[2]);
      actionIdle = animationMixer.current.clipAction(animations[3]);

      actions = [actionZero, actionOne, actionTwo, actionIdle];

      activateAllActions();
    }
  }, [scene, animations]);

  useFrame((state, delta) => {
    actionZeroWeight = actionZero.getEffectiveWeight();
    actionOneWeight = actionOne.getEffectiveWeight();
    actionTwoWeight = actionTwo.getEffectiveWeight();
    actionIdleWeight = actionIdle.getEffectiveWeight();

    setAllWeight(() => {
      const newWeights = new Array(4);
      newWeights[0] = actionZeroWeight;
      newWeights[1] = actionOneWeight;
      newWeights[2] = actionTwoWeight;
      newWeights[3] = actionIdleWeight;
      return newWeights;
    });

    if (actions[props.currentAnimation] != null) {
      if (actions[props.currentAnimation].time > 0.99) {
        actions[props.currentAnimation].stop().play();
      }
    }

    if (props.currentAnimation === 0) {
      crossFadeToAnimationZero(delta);
      activateAllActions();
    }
    if (props.currentAnimation === 1) {
      crossFadeToAnimationOne(delta);
      activateAllActions();
    }
    if (props.currentAnimation === 2) {
      crossFadeToAnimationTwo(delta);
      activateAllActions();
    }
    if (props.currentAnimation === 3) {
      crossFadeToIdle(delta);
      activateAllActions();
    }
    if (props.peakFrequency === 0) {
      return animationMixer.current && animationMixer.current.update(delta);
    }
    return (
      animationMixer.current &&
      animationMixer.current.update(delta * props.peakFrequency * 2)
    );
  });

  return scene ? (
    <group>
      <primitive
        object={scene}
        scale={[10.1, 10.1, 10.1]}
        position={props.position}
        rotation={new Euler(0, 90 + 45, 0)}
      />
    </group>
  ) : null;
};

export default Dancer;
