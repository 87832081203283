import React from "react";
import { extend } from '@react-three/fiber'
import {Mesh, BoxGeometry, MeshStandardMaterial, ColorManagement} from 'three'

extend({ Mesh, BoxGeometry, MeshStandardMaterial })

ColorManagement.enabled = true;

function Scene() {
  return (
    <mesh position={[0, -2.4, 0]}>
      <boxGeometry attach="geometry" args={[20, 0.3, 8]} />
      <meshStandardMaterial attach="material" color={"#554040"} />
    </mesh>
  );
}

export default Scene;
